
import '../bootstrap/dist/css/bootstrap.min.css';
import '../styles/index_body.css'
import NavFooter from '../components/nav_footer'
import image1 from '../images/image-1-index.png';
import image2 from '../images/image-2.png';
import image3 from '../images/image-3.png';





export default function IndexBody() {


    return (

        <>
            <div className="main-container">


                <div className="blue-container">
                    <div className="inside-blue-container">
                        <div className="blue-1">
                            <ul className="blue-1-ul">
                                <li className="blue-1-items"><img className="image-tag"src={image1} alt="giving-food" /></li>
                                <li className="blue-1-items"><p id="blue-text-1">Freely Gave is dedicated to sharing food with the community, embodying Christ's spirit of giving freely to all.</p></li>
                            </ul>
                        </div>

                        <div className="blue-2">
                            <ul className="blue-2-ul">
                                <li className="blue-2-items"><img className="image-tag" src={image2} alt="giving-clothes" /></li>
                                <li className="blue-2-items"><p id="blue-text-2">Freely Gave is committed to providing clothes to those in need, offering support where it is often unseen.</p></li>
                                
                                
                            </ul>

                        </div>

                        <div className="blue-3">
                            <ul className="blue-3-ul">
                                <li className="blue-3-items"><img className="image-tag" src={image3} alt="giving-hygiene" /></li>
                                <li className="blue-3-items"><p id="blue-text-3">Freely Gave is devoted to providing support in hygiene, ensuring everyone has access to essential care.</p></li>
                            </ul>
                        </div>
                    </div>

                </div>
                <NavFooter />
            </div>



            
        
        
        
        
        </>
    )

}