import {BrowserRouter, Routes, Route} from 'react-router-dom'
import Home from './pages/home'
import Donate from './pages/donate'
import Contact from './pages/contact'
import Community from './pages/community'
import AboutUs from './pages/aboutus'
import Page1 from './pages/page1'
import Page2 from './pages/page2'
import Page3 from './pages/page3'

import './App.css';

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route index element={<Home />}/>
          <Route path="/home" element={<Home/>} />
          <Route path="/donate" element={<Donate/>} />
          <Route path="/contact" element={<Contact/>} />
          <Route path="/community" element={<Community/>} />
          <Route path="/aboutus" element={<AboutUs/>} />
          <Route path="/community/communityimpact" element={<Page1/>} />
          <Route path="/community/fooddrive" element={<Page2/>} />
          <Route path="/community/clothingdrive" element={<Page3/>} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
