import '../bootstrap/dist/css/bootstrap.min.css';
import { Navbar, Nav, NavDropdown} from '../react-bootstrap/esm';
import '../styles/header.css'











export default function Header() {

      
    return (
        <>

            <header className="mainheader">
            
                <Navbar bg="transparent" expand="lg"  className="main-navbar">
                        <Navbar.Brand id="navbar-logo-header" href="/home">Freely Gave</Navbar.Brand>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="mr-auto elements-nav">
                            <Nav.Link className="nav-pages-header" href="/aboutus">About Us</Nav.Link>
                            <NavDropdown className="nav-pages-header" title="Community" id="basic-nav-dropdown-header">
                            <NavDropdown.Item href="/community/communityimpact" >Outreach</NavDropdown.Item>
                                <NavDropdown.Item href="/community/fooddrive" >Food Drive</NavDropdown.Item>
                                <NavDropdown.Item href="/community/clothingdrive">Clothing Drive</NavDropdown.Item>
                            </NavDropdown>
                            <Nav.Link href="/contact" className="nav-pages-header">Contact</Nav.Link>

                            <Nav.Link target="_blank"  className="donate-button" href="https://www.paypal.com/donate/?business=MFDUYFQSWXXSG&no_recurring=0&currency_code=USD" className="nav-pages-header">Donate</Nav.Link>

                            </Nav>

                        </Navbar.Collapse>
                </Navbar>
            </header>
        </>

    )
}