
import '../bootstrap/dist/css/bootstrap.min.css';
import { Navbar, Nav, NavDropdown} from '../react-bootstrap/esm';
import '../styles/nav_header.css'
import what_we_do from '../images/what_we_do_image.png';









export default function NavHeader() {

      
    return (
        <>

            <header className="navbarheader">
            
                <Navbar bg="transparent" expand="lg"  className="main-navbar">
                        <Navbar.Brand id="navbar-logo" href="/home">Freely Gave</Navbar.Brand>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="mr-auto elements-nav">
                            <Nav.Link className="nav-pages" href="/aboutus">About Us</Nav.Link>
                            <NavDropdown className="nav-pages" title="Community" id="basic-nav-dropdown">
                            <NavDropdown.Item href="/community/communityimpact" >Outreach</NavDropdown.Item>
                                <NavDropdown.Item href="/community/fooddrive" >Food Drive</NavDropdown.Item>
                                <NavDropdown.Item href="/community/clothingdrive">Clothing Drive</NavDropdown.Item>
                            </NavDropdown>
                            <Nav.Link href="/contact" className="nav-pages">Contact</Nav.Link>

                            <Nav.Link className="donate-button"target="_blank" href="https://www.paypal.com/donate/?business=MFDUYFQSWXXSG&no_recurring=0&currency_code=USD" className="nav-pages">Donate</Nav.Link>

                            </Nav>

                        </Navbar.Collapse>
                </Navbar>
            </header>

            <section className="what-we-do">
                <h2 id="what-we-do-message">What We Do</h2>
                <p id="what-we-do">Give unto others as Christ has given unto you</p>
                <img id="what-we-do-img" src={what_we_do} alt="What We Do" />
            </section>
        </>

    )
}


