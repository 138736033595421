import Header from '../components/header'
import Footer from '../components/footer'
import '../styles/aboutus_body.css'



export default function Page2() {

    return (
        <>
            
            <Header />
            <body className="body-container">

                <div className="main-container-aboutus">
                    <p className="aboutus-text">
                        Food Drive

                    </p>

                </div>


            </body>
            <Footer />

            
        
        </>
    )
}