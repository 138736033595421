import Header from '../components/nav_header'


export default function Community() {

    return (
        <>
            <Header />
            <h1>This is the Community Page</h1>
        
        </>
    )
}