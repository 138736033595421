
import Header from '../components/header'
import Footer from '../components/footer'
import '../styles/aboutus_body.css'
import '../styles/contact_body.css'





export default function Contact() {
    document.addEventListener('DOMContentLoaded', function() {
        const textarea = document.querySelector('.message-area');
        
        function autoExpand(field) {
            // Reset field height
            field.style.height = 'inherit';
    
            // Get the computed styles for the element
            const computed = window.getComputedStyle(field);
    
            // Calculate the height
            const height = parseInt(computed.getPropertyValue('border-top-width'), 10)
                         + parseInt(computed.getPropertyValue('padding-top'), 10)
                         + field.scrollHeight
                         + parseInt(computed.getPropertyValue('padding-bottom'), 10)
                         + parseInt(computed.getPropertyValue('border-bottom-width'), 10);
    
            field.style.height = `${height}px`;
        }
    
        textarea.addEventListener('input', function() {
            autoExpand(this);
        });
    });
    
    return (
        <>
            
            <Header />
            <body className="body-container-contact">

                <div className="main-container-contact">
                    <p className="contact-title">Contact Us</p>
                    <form action="https://formspree.io/f/moqgoqev" method="POST">
                        <div className="form-container">
                            <label className="label-text">
                                Your email:
                                <input placeholder="Email" className="input-tags"type="email" name="email"/>
                            </label>
                            <label className="label-text">
                                Your message:
                                <textarea placeholder="Start Typing..."className="message-area"name="message"></textarea>
                            </label>
                            {/* <!-- your other form fields go here --> */}
                            <button id="submit-button" type="submit">Send</button>
                        </div>
                    </form>
                </div>


            </body>
            <Footer />

            
        
        </>
    )
}
