import NavHeader from '../components/nav_header';
import IndexBody from '../components/index_body';
// Import the image at the top of your Home.js file


export default function Home() {


    return (
        <>
            
            <NavHeader />

            <IndexBody />

            
        </>
    );
}
