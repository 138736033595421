
import '../bootstrap/dist/css/bootstrap.min.css';
import '../styles/footer.css'
import footerimg from '../images/freelygavelogo.png';


export default function Footer() {

    return (
        <>
        
            <div className="main-container-footer">
                <ul className="main-ul">
                    <li className="items li-1">
                        <li >
                            <p id="freelygave-name-footer">Freely Gave</p>
                        </li>

                        <li className="2-li">
                            {/* <p className="address-footer">111-50 144th 11435</p> */}
                            <p className="address-footer">929-260-0312</p>

                        </li>
                        <li>
                            <ul className="social-media-ul">
                                <li className="social-media-icons"><a href="https://www.instagram.com/freelygave/" id="instagram-icon"><i class="fa-brands fa-instagram fa-2xl"></i></a></li>
                                <li className="social-media-icons"><a href="#" id="facebook-icon"><i class="fa-brands fa-facebook fa-2xl"></i></a></li>
                                <li className="social-media-icons"><a href="#" id="x-icon"><i class="fa-brands fa-x-twitter fa-2xl"></i></a></li>
                            </ul>
                        </li>
                        
                    </li>

                    <li className="items">
                        <ul className="secondary-ul">
                            <li >
                                <li className="pages-second"><a className="pages1-footer" href="/aboutus">About Us</a></li>
                                <li className="pages-second"><a className="pages1-footer" target="_blank" href="https://www.paypal.com/donate/?business=MFDUYFQSWXXSG&no_recurring=0&currency_code=USD">Donate</a></li>

                            </li>
                            <li className="pages-second"><a className="pages2-footer" href="/contact">Contact</a></li>
                        </ul>

                    </li>

                    <li className="items">
                        <img  id="footer-image" src={footerimg} alt="freely-gave-logo"/>

                    </li>



                </ul>


            </div>
        
        
        
        </>



    )



}