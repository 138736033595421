import Header from '../components/header'
import Footer from '../components/footer'
import '../styles/aboutus_body.css'




export default function AboutUs() {

    return (
        <>
            
            <Header />
            <body className="body-container">

                <div className="main-container-aboutus">
                    <p className="aboutus-text">This is the about us for Freely Gave. How we are started. Our belief system. What we intend to accomplish with our ministry. More content will be here. 

                    </p>

                </div>


            </body>
            <Footer />

            
        
        </>
    )
}